import {
  PersonSearchNoResults,
  SerializedObituaryDetails,
} from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  getObituarySearchParams,
} from '@ltvco/refresh-lib/v2';
import { ObituaryCards } from '../Cards/ObituaryCards';
import { PersonCardLoading } from '../Loading/PersonCardLoading';
import { ObituaryTable } from '../ResultTables/TableTypes/ObituaryTable';

interface ObituaryResultsContainerProps {
  searchData: AdvancedSearchURLParams;
  results: SerializedObituaryDetails | undefined;
  isInTableView?: boolean;
}

export function ObituaryResultsContainer({
  searchData,
  results,
  isInTableView,
}: ObituaryResultsContainerProps) {
  return !results ? (
    <PersonCardLoading />
  ) : results.length === 0 ? (
    <PersonSearchNoResults />
  ) : isInTableView ? (
    <ObituaryTable
      searchObituaryResult={results}
      searchObituaryQuery={getObituarySearchParams(searchData)}
    />
  ) : (
    <ObituaryCards
      searchObituaryResult={results}
      searchObituaryQuery={getObituarySearchParams(searchData)}
    />
  );
}
