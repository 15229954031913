export interface FamilySearchRowValue {
  id: string;
  name: JSX.Element;
  age: JSX.Element;
  locations: JSX.Element;
  relatives: JSX.Element;
}

export interface PersonRowValue {
  id: string;
  name: JSX.Element;
  age: JSX.Element;
  aliases: JSX.Element;
  locations: JSX.Element;
  relatives: JSX.Element;
}
export interface ContactRowValue {
  id: string;
  name: JSX.Element;
  job: string;
  companies: JSX.Element;
  locations: JSX.Element;
}

export interface ObituaryRowValue {
  id: string | null;
  name: JSX.Element;
  locations: JSX.Element;
  born: string;
  death: string;
}
export interface ResidentRowValue {
  id: string;
  name: JSX.Element;
  age: JSX.Element;
  aliases: JSX.Element;
  dateSeen: JSX.Element;
}
export type ViewTypes = 'table' | 'list';
export const viewTypes = {
  TABLE: 'table' as ViewTypes,
  LIST: 'list' as ViewTypes,
};

export type TableTypes =
  | 'person'
  | 'genealogy'
  | 'obituary'
  | 'professional'
  | 'address';

export const tableTypes = {
  PERSON: 'person' as TableTypes,
  GENEALOGY: 'genealogy ' as TableTypes,
  OBITUARY: 'obituary' as TableTypes,
  PROFESSIONAL: 'professional' as TableTypes,
  ADDRESS: 'address' as TableTypes,
};
export type SearchType =
  | 'username'
  | 'phone'
  | 'email'
  | 'person'
  | 'property'
  | 'obituary'
  | 'contact'
  | 'ancestor'
  | 'vehicle'
  | 'email-fraud'
  | 'phone-fraud'
  | 'ip-fraud'
  | 'url-fraud';

export interface RelativeNotSerialized {
  bvid?: string;
}
