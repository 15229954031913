export const personHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'age', label: 'Age' },
  { id: 'aliases', label: 'Aliases' },
  { id: 'locations', label: 'Location' },
  { id: 'relatives', label: 'Relatives' },
];

export const familySearchHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'age', label: 'Year of birth' },
  { id: 'locations', label: 'Location' },
  { id: 'relatives', label: 'Relatives' },
];

export const obituaryHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'locations', label: 'Location' },
  { id: 'born', label: 'Born' },
  { id: 'death', label: 'Death' },
];

export const contactHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'job', label: 'Job Title' },
  { id: 'companies', label: 'Company' },
  { id: 'locations', label: 'Location' },
];

export const residentHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'age', label: 'Age' },
  { id: 'aliases', label: 'Aliases' },
  { id: 'phones', label: 'Phone' },
  { id: 'dateSeen', label: 'Date Seen' },
];

export const TABLE_LIST_LIMIT = 5;
