import { Box, Stack, styled, Text } from '@ltvco/refresh-lib/theme';

export const IconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s ease',
}));

export const StyledResultsTableHead = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 1,
}));

export const StyledResultsTableRow = styled(Stack)(({ theme }) => ({
  padding: '8px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  transition: 'background-color 0.3s ease',
  border: `1px solid ${theme.palette.divider}`,
  ':hover': {
    backgroundColor: theme.palette.success.light,
  },
  '&:hover .icon-container': {
    width: '24px',
    height: '24px',
  },
}));

export const StyledResultsTableCell = styled(Stack)(() => ({
  width: '20%',
  padding: '16px 12px',
  '&.table-header': {
    padding: '8px 12px',
    lineHeight: '1',
    height: '24px',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const StyledResultsTableContainer = styled(Stack)(({}) => ({
  position: 'relative',
  right: '0',
  borderRadius: '4px',
  padding: '0 0 12px 0',
  marginRight: '1px',
  overflowY: 'scroll',
  maxHeight: '85.5vh',
  '::-webkit-scrollbar': {
    width: '5px',
    position: 'relative',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    borderRadius: '10px',
  },
}));

export const StyledResultsTableText = styled(Text)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&.link': {
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    cursor: 'pointer',
  },
}));

export const StyledResultsTableNameColumn = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: '30px',
  gap: 4,
}));
