import {
  ContactCompany,
  openReportInNewTab,
  SerializedContactTeaserData,
  useReportRedirect,
} from '@ltvco/refresh-lib/v1';
import { constants } from 'appConstants';
import { ListColumn, setValidListInfo } from '../TableComponents/ListColumn';
import { NameColumn } from '../TableComponents/NameColumn';
import { ContactRowValue, tableTypes } from '../types';
import { contactHeaders } from '../constants';
import { TableContainer } from '../TableComponents/TableContainer';

interface ContactTableProps {
  results: SerializedContactTeaserData;
}
export interface UsableContactData {
  name: string;
  contactId: string;
  job: string;
  companies: string[];
  locations: string[];
}

export const ContactTable = ({ results }: ContactTableProps) => {
  const { contacts = [] } = results;
  const { redirectToSearchContactUrl } = useReportRedirect();

  const handleCompanyClick = (company: string | ContactCompany) => {
    if (typeof company === 'string') {
      redirectToSearchContactUrl({ company });

      return;
    }

    redirectToSearchContactUrl({ company: company.name });
  };
  const handleViewReport = (contactId: string) => {
    openReportInNewTab(
      {
        contact_id: contactId,
        searchType: 'contact',
      },
      constants.links.baseUrl
    );
  };

  const data: UsableContactData[] = contacts.map((contact) => {
    const contactData = {
      name: contact?.name ?? '',
      job: contact?.title ?? '',
      contactId: contact?.id ?? '',
      companies:
        contact?.companies
          ?.filter((company) => company.name)
          ?.map((company) => company.name) || [],
      locations: [contact?.address ?? ''],
    };
    return contactData;
  });

  const showRow = (person: UsableContactData): ContactRowValue => {
    const columnFornName = (
      <NameColumn
        name={person.name}
        id={person.contactId}
        goToReport={handleViewReport}
        nameMatched={false}
      />
    );
    const columnForCompanies = (
      <ListColumn
        list={setValidListInfo(person.companies, person.companies)}
        goToReport={handleCompanyClick}
      />
    );
    const columnForLocations = (
      <ListColumn list={setValidListInfo(person.locations)} />
    );

    const rowValue = {
      id: person.contactId,
      name: columnFornName,
      job: person.job,
      companies: columnForCompanies,
      locations: columnForLocations,
    };
    return rowValue;
  };

  return (
    <TableContainer
      results={data}
      showRow={showRow}
      headers={contactHeaders}
      tableType={tableTypes.PROFESSIONAL}
      goToReport={handleViewReport}
    />
  );
};
