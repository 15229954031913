import {
  IconContainer,
  StyledResultsTableNameColumn,
  StyledResultsTableText,
} from '../components';
import { Stack, DarkTooltip, Box } from '@ltvco/refresh-lib/theme';
import { titleize } from '@ltvco/refresh-lib/utils';
import { Person } from '@mui/icons-material';
import { Spellcheck } from 'icons';
import { removeNumberPattern } from '../utils';
import { UsableResidentData } from '../TableTypes/ResidentTable';

interface NameColumnProps {
  name: string;
  nameMatched: boolean;
  id?: string | UsableResidentData;
  goToReport: (bvid: any) => void;
}
export const NameColumn = (props: NameColumnProps) => {
  const { name, nameMatched, id, goToReport } = props;
  return (
    <StyledResultsTableNameColumn>
      <IconContainer className="icon-container">
        <Person color="primary" sx={{ fontSize: '16px', height: '16px' }} />
      </IconContainer>
      <StyledResultsTableText
        className={id ? 'link' : ''}
        onClick={() => id && goToReport(id)}
      >
        {name && removeNumberPattern(titleize((name as string).toLowerCase()))}
      </StyledResultsTableText>
      <Stack flexDirection="row">
        {nameMatched && (
          <DarkTooltip
            title="Name Matched"
            enterTouchDelay={0}
            leaveTouchDelay={2000}
          >
            <Box>
              <Spellcheck
                sx={(theme) => ({
                  height: '18px',
                  width: '18px',
                  color: theme.palette.primary.main,
                })}
              />
            </Box>
          </DarkTooltip>
        )}
      </Stack>
    </StyledResultsTableNameColumn>
  );
};
