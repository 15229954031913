import {
  ContactResultSkeleton,
  NoContactResults,
  SerializedContactTeaserData,
} from '@ltvco/refresh-lib/v1';
import { ContactCards } from '../Cards/ContactCards';
import { ContactTable } from '../ResultTables/TableTypes/ContactTable';

interface ContactResultsContainerProps {
  results: SerializedContactTeaserData | undefined;
  isInTableView?: boolean;
}

export function ContactResultsContainer({
  results,
  isInTableView,
}: ContactResultsContainerProps) {
  return !results ? (
    <ContactResultSkeleton />
  ) : results.total <= 0 ? (
    <NoContactResults />
  ) : isInTableView ? (
    <ContactTable results={results} />
  ) : (
    <ContactCards searchContactResult={results} />
  );
}
