import { useSortedObitResults } from '@ltvco/refresh-lib/hooks';
import { SerializedObituaryDetails } from '@ltvco/refresh-lib/v1';
import { reportGetters } from '@ltvco/refresh-lib/v1';
import {
  ObituaryRowValue,
  tableTypes,
} from 'routes/SearchPerson/ResultTables/types';
import { NameColumn } from '../TableComponents/NameColumn';
import { ListColumn, setValidListInfo } from '../TableComponents/ListColumn';
import { titleize } from '@ltvco/refresh-lib/utils';
import { TableContainer } from '../TableComponents/TableContainer';
import { obituaryHeaders } from '../constants';
import { constants } from 'appConstants';
import { useQueryParams } from 'utils/useQueryParams';
import { formatShorthandDate } from '../utils';

interface ObituaryTableProps {
  searchObituaryResult: SerializedObituaryDetails;
  searchObituaryQuery: any;
}
export interface UsableObituaryData {
  name: string;
  id: string;
  locations: string;
  dateOfBirth: string;
  dateOfDeath: string;
}
export const ObituaryTable = ({
  searchObituaryQuery,
  searchObituaryResult,
}: ObituaryTableProps) => {
  const sortedObits = useSortedObitResults(
    searchObituaryResult,
    searchObituaryQuery
  );

  const queryParams = useQueryParams();
  const goToObituaryReport = async (id: string) => {
    queryParams.set('id', id || '');
    const response = await reportGetters.obituary({ queryParams });
    const permalink = response?.report?.permalink;
    const windowObjectReference = window.open();

    if (!permalink) {
      if (windowObjectReference) {
        windowObjectReference.close();
      }

      return;
    }
    const windowTarget = windowObjectReference || window;
    windowTarget.location.href = `${constants.links.baseUrl}/report/obituary?permalink=${permalink}`;
  };

  const data: UsableObituaryData[] = sortedObits.map((person) => {
    const { name, dob, dod, id } = person;
    const obituaryData = {
      name: name ?? '',
      id: id ?? '',
      locations: `${
        (person.city ?? '') +
        (person.city && person.state ? ', ' : '') +
        (person?.state ?? '')
      }`,
      dateOfBirth: dob ? formatShorthandDate(dob) ?? '' : '',
      dateOfDeath: dod ? formatShorthandDate(dod, true) ?? '' : '',
    };

    return obituaryData;
  });

  const showRow = (person: UsableObituaryData): ObituaryRowValue => {
    const columnFornName = (
      <NameColumn
        name={titleize((person.name as string).toLowerCase())}
        nameMatched={false}
        id={person.id ?? ''}
        goToReport={goToObituaryReport}
      />
    );
    const columnForLocations = (
      <ListColumn list={setValidListInfo([person.locations])} />
    );
    const rowValue = {
      id: person.id,
      name: columnFornName,
      locations: columnForLocations,
      born: person.dateOfBirth,
      death: person.dateOfDeath || '',
    };
    return rowValue;
  };
  return (
    <TableContainer
      tableType={tableTypes.OBITUARY}
      results={data}
      showRow={showRow}
      headers={obituaryHeaders}
      goToReport={goToObituaryReport}
    />
  );
};
