import { Stack } from '@ltvco/refresh-lib/theme';
import { titleize } from '@ltvco/refresh-lib/utils';
import { StyledResultsTableText } from '../components';
import { TABLE_LIST_LIMIT } from '../constants';

export interface ListInfo {
  fullname: string;
  bvid?: string;
}
export interface ListColumnProps {
  list: ListInfo[] | [];
  goToReport?: (bvid: string) => void;
}

export const setValidListInfo = (
  words?: string[],
  bvids?: string[] | string
): ListInfo[] | [] => {
  if (words !== undefined && words.length > 0) {
    const lists: ListInfo[] = words.map((word, index) => {
      const list = {
        fullname: word,
        bvid: bvids ? (Array.isArray(bvids) ? bvids[index] : bvids) : undefined,
      };
      return list;
    });
    return lists;
  }
  return [];
};

export const ListColumn = (props: ListColumnProps) => {
  const { list, goToReport } = props;
  const extraItems =
    list.length > TABLE_LIST_LIMIT ? list.length - TABLE_LIST_LIMIT : 0;
  return (
    <Stack display="flex" flexDirection="column" gap={'4px'}>
      {list.map((item, index) => {
        if (index > TABLE_LIST_LIMIT) return;
        return (
          <StyledResultsTableText
            className={item?.bvid ? 'link' : ''}
            key={index}
            sx={{
              lineHeight: '22px',
            }}
            onClick={() => {
              if (item?.bvid !== undefined && goToReport) goToReport(item.bvid);
            }}
          >
            {item?.fullname
              ? titleize((item.fullname as string).toLowerCase())
              : ''}
          </StyledResultsTableText>
        );
      })}
      {extraItems !== 0 && (
        <StyledResultsTableText
          sx={{
            lineHeight: '22px',
          }}
        >
          + {extraItems} more
        </StyledResultsTableText>
      )}
    </Stack>
  );
};
