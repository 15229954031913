import {
  ContactRowValue,
  FamilySearchRowValue,
  ObituaryRowValue,
  PersonRowValue,
  ResidentRowValue,
  tableTypes,
  TableTypes,
} from 'routes/SearchPerson/ResultTables/types';
import {
  StyledResultsTableContainer,
  StyledResultsTableHead,
  StyledResultsTableRow,
  StyledResultsTableCell,
} from '../components';
import { LoadingReports } from 'routes/Dashboard/RecentReports/components';
import { Stack } from '@ltvco/refresh-lib/theme';

interface TableContainerProps {
  tableType: TableTypes;
  results: any[];
  headers: { id: string; label: string }[];
  showRow: (
    row: any
  ) =>
    | PersonRowValue
    | FamilySearchRowValue
    | ObituaryRowValue
    | ContactRowValue
    | ResidentRowValue;
  goToReport?: (id: any) => void;
}

export const TableContainer = ({
  tableType,
  results,
  headers,
  showRow,
  goToReport,
}: TableContainerProps) => {
  return (
    <StyledResultsTableContainer id="tableContainer">
      <Stack>
        <StyledResultsTableHead className="sticky-header">
          <StyledResultsTableRow className="table-header">
            {headers?.map((header) => (
              <StyledResultsTableCell className="table-header" key={header.id}>
                {header.label}
              </StyledResultsTableCell>
            ))}
          </StyledResultsTableRow>
        </StyledResultsTableHead>
        <Stack>
          {results && results.length > 0 ? (
            results.map((row) => {
              const rowValue = showRow(row);
              const id = rowValue?.id ?? crypto.randomUUID();
              return (
                <StyledResultsTableRow key={id}>
                  {headers.map((header) => {
                    const value = rowValue[header.id as keyof typeof rowValue];
                    const isLink =
                      (header.id === 'relatives' &&
                        tableType === tableTypes.PERSON) ||
                      (header.id === 'companies' &&
                        tableType === tableTypes.PROFESSIONAL) ||
                      header.id === 'name';
                    return (
                      <StyledResultsTableCell
                        key={`${id}-${header.id}`}
                        sx={{ cursor: !isLink ? 'pointer' : '' }}
                        onClick={() => {
                          if (goToReport && !isLink)
                            goToReport(rowValue?.id ?? '');
                        }}
                      >
                        {value}
                      </StyledResultsTableCell>
                    );
                  })}
                </StyledResultsTableRow>
              );
            })
          ) : (
            <LoadingReports />
          )}
        </Stack>
      </Stack>
    </StyledResultsTableContainer>
  );
};
