import { useAppConstantsContext } from '@ltvco/refresh-lib/ctx';
import { formatPhone, getDatesSeen, nameize } from '@ltvco/refresh-lib/utils';
import {
  mapResidentsToResidentInfo,
  openReportInExistingTab,
  openReportInNewTab,
  propertiesTypes,
  SerializedPropertyReport,
} from '@ltvco/refresh-lib/v1';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { AgeColumn } from '../TableComponents/AgeColumn';
import { ListColumn, setValidListInfo } from '../TableComponents/ListColumn';
import { NameColumn } from '../TableComponents/NameColumn';
import { TableContainer } from '../TableComponents/TableContainer';
import { tableTypes, ResidentRowValue, SearchType } from '../types';
import { residentHeaders } from '../constants';

interface ResidentTableProps {
  results: SerializedPropertyReport;
}

export interface UsableResidentData {
  bvid: string;
  name: string;
  age: string;
  aliases: string[];
  phones: string[];
  dateSeen: string;
  residentOriginalData: propertiesTypes.ResidentInfo;
}

export const ResidentTable = ({ results }: ResidentTableProps) => {
  const isInPartialAddressesVariation = useFeatureIsOn('oar-1075');
  const residentsList = results?.properties?.[0]?.residents || [];
  const residentInfo = mapResidentsToResidentInfo(
    residentsList,
    isInPartialAddressesVariation
  );
  const {
    links: { baseUrl },
    featureFlags: { shouldOpenReportInCurrentTab },
  } = useAppConstantsContext();

  const goToReport = (bvid: string) => {
    const tabData = {
      bvid: bvid || '',
      searchType: 'person' as SearchType,
      showPartialAddresses: isInPartialAddressesVariation,
    };
    if (shouldOpenReportInCurrentTab) {
      openReportInExistingTab(tabData, baseUrl);
    } else {
      openReportInNewTab(tabData, baseUrl);
    }
  };

  const data: UsableResidentData[] = residentInfo.map((resident, index) => {
    const rawResidentData = residentsList[index];
    const phones = rawResidentData?.phones ?? [];
    const residentData: UsableResidentData = {
      bvid: resident?.bvId ?? '',
      name: resident?.fullName ?? '',
      age: resident?.personSearch?.age ?? '',
      aliases: rawResidentData?.names
        .slice(1)
        .map((name) => nameize(name.full)),
      phones:
        phones.length > 0
          ? phones.map((phone) =>
              phone?.number ? formatPhone(phone.number) : ''
            )
          : [],
      dateSeen: rawResidentData?.addresses
        ? getDatesSeen(rawResidentData.addresses?.[0])
        : '-',
      residentOriginalData: resident,
    };
    return residentData;
  });

  const showRow = (resident: UsableResidentData): ResidentRowValue => {
    const columnForName = (
      <NameColumn
        name={resident.name}
        nameMatched={false}
        id={resident.bvid}
        goToReport={goToReport}
      />
    );
    const columnForAge = <AgeColumn age={resident.age} />;
    const columnForAliases = (
      <ListColumn list={setValidListInfo(resident.aliases)} />
    );
    const columnForPhones = (
      <ListColumn list={setValidListInfo(resident.phones)} />
    );
    const columnForDateSeen = (
      <ListColumn list={setValidListInfo([resident.dateSeen])} />
    );

    const rowValue = {
      id: resident.residentOriginalData.bvId,
      name: columnForName,
      age: columnForAge,
      aliases: columnForAliases,
      phones: columnForPhones,
      dateSeen: columnForDateSeen,
    };
    return rowValue;
  };
  return (
    <TableContainer
      tableType={tableTypes.ADDRESS}
      results={data}
      headers={residentHeaders}
      showRow={showRow}
      goToReport={goToReport}
    />
  );
};
