import {
  FamilySearchPersonData,
  getMonthAbbreviation,
  personAddresses,
  personRelatives,
  RawFamilySearchPersonResults,
} from '@ltvco/refresh-lib/fmt';
import { commonTypes, type Record } from '@ltvco/refresh-lib/v1';

export const removeNumberPattern = (word: string) => {
  return word.replace(/, ?\d+/g, '');
};

export const extractAddresses = (fullAddress: string): string[] => {
  if (!fullAddress) return [];
  return fullAddress?.split(',').map((item) => item.trim());
};

export const formattedFamilySearchDataForTable = (
  familySearchData: RawFamilySearchPersonResults,
  fName: string,
  lName: string
): Record[] => {
  return familySearchData.entities.options.people.map(
    (person: FamilySearchPersonData): Record => {
      return {
        age: person.identity.ages?.[0] ? String(person.identity.ages[0]) : '',
        name: `${person.identity.names[0].full} ${
          person.identity.ages?.[0] ? `,${person.identity.ages[0]}` : ''
        }`,
        birthInfo: `${getMonthAbbreviation(
          person.identity.dobs?.[0]?.date?.parsed?.month ?? ''
        )} ${person.identity.dobs?.[0]?.date?.parsed?.year || ''}`,
        relatives: personRelatives(person.connections?.relatives || []).filter(
          (relative): relative is string => relative !== null
        ),
        locations: extractAddresses(
          personAddresses(person.contact?.addresses || [])[0]
        ),
        nameMatched:
          person.identity.names[0].full
            ?.toLowerCase()
            .includes(fName.toLowerCase()) &&
          person.identity.names[0].full
            ?.toLowerCase()
            .includes(lName.toLowerCase()),
        familySearchId: person.identity.fs_ids[0],
        bvid: '',
      };
    }
  );
};

// Remove this function and use the one from the refresh-lib if the test gets hardcoded
export const formatShorthandDate = (
  date: commonTypes.Date | undefined,
  includeDay?: boolean
) => {
  if (!date) {
    return;
  }

  if (date.parsed) {
    const { parsed } = date;
    if (parsed.year && parsed.month) {
      return new Date(
        parsed.year,
        parsed.month - 1,
        parsed.day || 1
      ).toLocaleDateString('en-us', {
        year: 'numeric',
        month: includeDay ? '2-digit' : 'long',
        day: includeDay ? 'numeric' : undefined,
      });
    }
  }
};
