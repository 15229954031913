import { Text } from '@ltvco/refresh-lib/theme';
interface AgeColumnProps {
  age?: string;
  birthInfo?: string;
}
export const AgeColumn = (props: AgeColumnProps): JSX.Element => {
  const { age, birthInfo } = props;
  return (
    <>
      <Text
        variant="subtitle2"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      >
        {age && age !== '-' ? age : ''}
      </Text>
      <Text
        variant="caption"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      >
        {birthInfo && birthInfo.trim() !== '' ? `Born ${birthInfo}` : ''}
      </Text>
    </>
  );
};
