import { useState, useRef } from 'react';
import {
  Box,
  useTheme,
  useMediaQuery,
  Divider,
  Button,
  Text,
  Stack,
  Link,
  IconButton,
} from '@ltvco/refresh-lib/theme';
import { ArrowBack } from '@mui/icons-material';
import { SidebarContainer, SubmitButton } from './components';
import {
  AdvancedSearchURLParams,
  ConsolidatedFilter,
  ConsolidatedFilterInfo,
  ConsolidatedSidebar,
  parseConsolidatedFilterInfo,
} from '@ltvco/refresh-lib/v2';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';

export interface ResultFilterProps {
  advancedSearchUrlParams: AdvancedSearchURLParams;
  onCollapse?: () => void;
}

export function PersonFilters({
  advancedSearchUrlParams,
  onCollapse,
}: ResultFilterProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const isInNewConsolidatedFilterVariant = useFeatureIsOn('prop-2853');
  const isInCollapseFilterVariant = useFeatureIsOn('prop-2887');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  const consolidatedFilterDefaults = parseConsolidatedFilterInfo(
    advancedSearchUrlParams
  );
  const filterRef = useRef<{
    clearFields: () => void;
    submitForm: () => void;
  } | null>(null);

  const handleExternalSubmit = () => {
    if (filterRef.current) {
      filterRef.current.submitForm();
    }
  };

  const handleExternalClear = () => {
    if (filterRef.current) {
      filterRef.current.clearFields();
    }
  };

  const collapsible = {
    isCollapsed: isSidebarCollapsed,
    setIsCollapsed: setIsSidebarCollapsed,
  };

  const ShowExternalButton = () => {
    if (isDesktop) {
      return (
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '20px 0',
            borderRadius: '10px',
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {isInNewConsolidatedFilterVariant && (
              <Link
                onClick={() => handleExternalClear()}
                data-cy="clear_all_fields_button"
                sx={{
                  cursor: 'pointer',
                  margin: '0 10px',
                  width: '50%',
                }}
              >
                <Text
                  variant="h5"
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    marginBottom: '0',
                    textAlign: 'center',
                  })}
                >
                  Reset
                </Text>
              </Link>
            )}

            <SubmitButton
              id="filters-toggle"
              type="button"
              name="filters-toggle"
              variant="contained"
              fullWidth
              onClick={handleExternalSubmit}
              sx={{
                display: isDesktop ? 'block' : 'none',
                width: isInNewConsolidatedFilterVariant ? '72%' : '100%',
              }}
            >
              {isInNewConsolidatedFilterVariant
                ? 'Show Results'
                : 'Refine Results'}
            </SubmitButton>
          </Stack>
        </Box>
      );
    }
  };
  return (
    <Box
      sx={{
        border: isTabletOrMobile ? 'unset' : '1px solid',
        borderColor: isTabletOrMobile ? 'unset' : 'divider',
        borderRadius: isTabletOrMobile ? 'unset' : '10px',
        marginLeft: 'unset',
        position: 'relative',
      }}
    >
      {isDesktop && isInCollapseFilterVariant && (
        <IconButton
          onClick={onCollapse}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            zIndex: 1,
          }}
          size="small"
        >
          <ArrowBack />
        </IconButton>
      )}
      <SidebarContainer
        sx={{
          height: isTabletOrMobile ? 'unset' : '74vh',
          borderRadius: '10px',
        }}
      >
        <ConsolidatedSidebar
          hideSubmitButton={true}
          collapsible={collapsible}
          handleExternalSubmit={handleExternalSubmit}
        >
          <ConsolidatedFilter
            ref={filterRef}
            formRef={formRef}
            defaults={consolidatedFilterDefaults as ConsolidatedFilterInfo}
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
          />
        </ConsolidatedSidebar>
      </SidebarContainer>
      <ShowExternalButton />
    </Box>
  );
}
