import { useEffect, useState } from 'react';
import {
  Stack,
  Text,
  useTheme,
  useMediaQuery,
} from '@ltvco/refresh-lib/theme';
import { useSearchParams } from 'react-router-dom';
import { PersonFilters } from './SearchFilter/PersonFiltersSidebar';
import {
  ConsolidatedStickyContainer,
  PersonFiltersContainer,
  ResultsContainer,
  SearchResultsGrid,
} from './components';
import SearchHeaderCarousel from './SearchFilter/SearchHeader/SearchHeaderCarousel';
import { SearchHeaderDesktop } from './SearchFilter/SearchHeader/SearchHeaderDesktop';
import {
  Record,
  SerializedContactTeaserData,
  SerializedObituaryDetails,
  SerializedPropertyReport,
} from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  CollectionType,
  OptionType,
  getSearchQueryParams,
} from '@ltvco/refresh-lib/v2';
import { PersonResultsContainer } from './ResultsContainers/PersonResultsContainer';
import { ObituaryResultsContainer } from './ResultsContainers/ObituaryResultsContainer';
import { ResidentResultsContainer } from './ResultsContainers/ResidentResultsContainer';
import { ContactResultsContainer } from './ResultsContainers/ContactResultsContainer';
import { FamilySearchResultsContainer } from './ResultsContainers/FamilySearchResultsContainer';
import { PersonFetcher } from './Fetchers/PersonFetcher';
import { ObituaryFetcher } from './Fetchers/ObituaryFetcher';
import { FamilySearchFetcher } from './Fetchers/FamilySearchFetcher';
import { ContactFetcher } from './Fetchers/ContactFetcher';
import { ResidentFetcher } from './Fetchers/ResidentFetcher';
import {
  formattedFamilySearchData,
  RawFamilySearchPersonResults,
} from '@ltvco/refresh-lib/fmt';
import { capitalizeEachWord } from '@ltvco/refresh-lib/utils';
import { viewTypes, ViewTypes } from './ResultTables/types';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
export function SearchPersonResultsv2() {
  const isInCollapseFilterVariant = useFeatureIsOn('prop-2887');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [searchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState(
    CollectionType.PUBLIC_RECORDS
  );
  const [manualSwitch, setManualSwitch] = useState<boolean>(false);
  const [isFilterCollapsed, setIsFilterCollapsed] = useState<boolean>(false);

  const [personResults, setPersonResults] = useState<Record[] | undefined>(
    undefined
  );
  const [obituaryResults, setObituaryResults] = useState<
    SerializedObituaryDetails | undefined
  >(undefined);
  const [contactResults, setContactResults] = useState<
    SerializedContactTeaserData | undefined
  >(undefined);
  const [residentResults, setResidentResults] = useState<
    SerializedPropertyReport | undefined
  >(undefined);
  const [familySearchResults, setFamilySearchResults] = useState<
    RawFamilySearchPersonResults | undefined
  >(undefined);
  const [currentView, setCurrentView] = useState<ViewTypes>(viewTypes.LIST);

  const isOnPersonResultsTableViewVariation = useFeatureIsOn('prop-2886');
  const isInTableView =
    isDesktop &&
    isOnPersonResultsTableViewVariation &&
    currentView === viewTypes.TABLE;

  const searchData: AdvancedSearchURLParams =
    getSearchQueryParams(searchParams);

  const { advancedSearch, source, fName, lName } = searchData;

  const personResultsCount: number = personResults?.length ?? 0;
  const obituaryResultsCount: number = obituaryResults?.length ?? 0;
  const contactResultsCount: number = contactResults?.total ?? 0;
  const residentResultsCount: number =
    residentResults?.properties?.[0]?.residents?.length ?? 0;
  const familySearchResultsCount: number = familySearchResults
    ? formattedFamilySearchData(familySearchResults, fName, lName).length
    : 0;

  const formatLength = (length: number | undefined): string => {
    const threshold = 99;
    if (length === undefined || length === null) {
      return '';
    }
    return length > threshold ? '99+' : length.toString();
  };

  useEffect(() => {
    document.title = 'Search - BeenVerified';
  }, []);

  const fetchPerson =
    !advancedSearch || source.includes(CollectionType.PUBLIC_RECORDS);
  const fetchObituary =
    !advancedSearch || source.includes(CollectionType.OBITUARIES);
  const fetchAncestor =
    !advancedSearch || source.includes(CollectionType.FAMILY_SEARCH);
  const fetchContact =
    !advancedSearch || source.includes(CollectionType.CONTACTS);
  const fetchResident =
    !advancedSearch || source.includes(CollectionType.RESIDENTS);

  const options: OptionType[] = [
    {
      label: 'Public Records',
      value: CollectionType.PUBLIC_RECORDS,
      length: formatLength(personResultsCount),
      visible: fetchPerson,
    },
    {
      label: 'FamilySearch',
      value: CollectionType.FAMILY_SEARCH,
      length: formatLength(familySearchResultsCount),
      visible: fetchAncestor,
    },
    {
      label: 'Obituary',
      value: CollectionType.OBITUARIES,
      length: formatLength(obituaryResultsCount),
      visible: fetchObituary,
    },
    {
      label: 'Contact',
      value: CollectionType.CONTACTS,
      length: formatLength(contactResultsCount),
      visible: fetchContact,
    },
    {
      label: 'Resident',
      value: CollectionType.RESIDENTS,
      length: formatLength(residentResultsCount),
      visible: fetchResident,
    },
  ];

  const handleTabSwitch = (option: CollectionType) => {
    setSelectedOption(option);
    setManualSwitch(true);
  };

  // useEffect to handle automatic switching based on `searchData` but only if not manually switched
  useEffect(() => {
    if (!manualSwitch) {
      const hasContactInfo =
        searchData.title !== '' ||
        searchData.company !== '' ||
        searchData.industry !== '' ||
        searchData.level?.some((level) => level !== '');
      const hasObituaryInfo =
        searchData.birthYear !== '' ||
        searchData.birthMonth !== '' ||
        searchData.deathYear !== '' ||
        searchData.deathMonth !== '';
      const hasResidentInfo = searchData.address !== '';

      if (
        hasContactInfo &&
        (source.length === 0 || source.includes(CollectionType.CONTACTS))
      ) {
        setSelectedOption(CollectionType.CONTACTS);
        return;
      } else if (
        hasObituaryInfo &&
        (source.length === 0 || source.includes(CollectionType.OBITUARIES))
      ) {
        setSelectedOption(CollectionType.OBITUARIES);
        return;
      } else if (
        hasResidentInfo &&
        (source.length === 0 || source.includes(CollectionType.RESIDENTS))
      ) {
        setSelectedOption(CollectionType.RESIDENTS);
        return;
      }
    }
  }, [searchData, manualSwitch, selectedOption]);

  useEffect(() => {
    if (advancedSearch) {
      setSelectedOption(source[0] as CollectionType);
    }
  }, [searchParams.get('source')]);

  const fetchers = () => {
    return (
      <>
        {fetchPerson && (
          <PersonFetcher
            setPersonResults={setPersonResults}
            searchData={searchData}
          />
        )}
        {fetchObituary && (
          <ObituaryFetcher
            setObituaryResults={setObituaryResults}
            searchData={searchData}
          />
        )}
        {fetchAncestor && (
          <FamilySearchFetcher
            setFamilySearchResults={setFamilySearchResults}
            searchData={searchData}
          />
        )}
        {fetchContact && (
          <ContactFetcher
            setContactResults={setContactResults}
            searchData={searchData}
          />
        )}
        {fetchResident && (
          <ResidentFetcher
            setResidentResults={setResidentResults}
            searchData={searchData}
          />
        )}
      </>
    );
  };

  const renderResults = () => {
    switch (selectedOption) {
      case CollectionType.FAMILY_SEARCH:
        return (
          <FamilySearchResultsContainer
            results={familySearchResults}
            searchData={searchData}
            isInTabletView={isInTableView}
          />
        );
      case CollectionType.CONTACTS:
        return (
          <ContactResultsContainer
            results={contactResults}
            isInTableView={isInTableView}
          />
        );
      case CollectionType.OBITUARIES:
        return (
          <ObituaryResultsContainer
            results={obituaryResults}
            searchData={searchData}
            isInTableView={isInTableView}
          />
        );
      case CollectionType.RESIDENTS:
        return (
          <ResidentResultsContainer
            results={residentResults}
            isInTableView={isInTableView}
          />
        );
      case CollectionType.PUBLIC_RECORDS:
        return (
          <PersonResultsContainer
            results={personResults}
            isInTableView={isInTableView}
          />
        );
      default:
        return null;
    }
  };

  const capitalizeFullName = capitalizeEachWord(searchData.fullName);
  const ShowHeaderTabs = () => {
    if (isDesktop) {
      return (
        <SearchHeaderDesktop
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={handleTabSwitch}
          searchData={searchData}
          currentView={currentView}
          setCurrentView={setCurrentView}
          isFilterCollapsed={isFilterCollapsed}
          setIsFilterCollapsed={setIsFilterCollapsed}
        />
      );
    }
    return (
      <SearchHeaderCarousel
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={handleTabSwitch}
      />
    );
  };

  const filterContainerStyles = () => {
    let styles = {
      width: '100%',
      maxWidth: '100%',
      top: 42,
    };

    if (isDesktop) {
      styles = {
        ...styles,
        width: '25% !important',
        maxWidth: '25% !important',
      };
    }

    if (isInCollapseFilterVariant && isDesktop) {
      if (isFilterCollapsed) {
        return {
          ...styles,
          transform: 'translateX(-100%)',
          opacity: 0,
          position: 'fixed',
          left: 0,
          transition: 'transform 0.1s ease-in-out, opacity 0.1s ease-in-out',
          pointerEvents: 'none',
          zIndex: 1,
        };
      }

      return {
        ...styles,
        transform: 'translateX(0)',
        opacity: 1,
        position: 'sticky',
        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        pointerEvents: 'auto',
        zIndex: 1,
      };
    }

    return styles;
  };

  return (
    <Stack
      sx={{
        minHeight: 800,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <SearchResultsGrid container spacing={3}>
        <PersonFiltersContainer
          item
          xs={12}
          md={
            isInCollapseFilterVariant && isDesktop && isFilterCollapsed ? 3 : 12
          }
          sx={filterContainerStyles()}
        >
          <ConsolidatedStickyContainer>
            <PersonFilters
              advancedSearchUrlParams={searchData}
              onCollapse={() => setIsFilterCollapsed(true)}
            />
          </ConsolidatedStickyContainer>
        </PersonFiltersContainer>
        <ResultsContainer
          item
          xs={12}
          md={isFilterCollapsed ? 12 : 9}
          sx={{ mt: { xs: 6.25, md: 0 } }}
        >
          <ShowHeaderTabs />
          {!isDesktop && (
            <Stack
              flexDirection="row"
              alignItems="center"
              mr={1.25}
              mb={2.5}
              pt={2}
            >
              <Text
                variant="h3"
                sx={{
                  fontWeight: 'normal',
                  marginRight: '5px',
                  marginBottom: '0',
                }}
              >
                Results for:
              </Text>
              <Text variant="h3" m={0}>
                {capitalizeFullName}
              </Text>
            </Stack>
          )}
          {fetchers()}
          {renderResults()}
        </ResultsContainer>
      </SearchResultsGrid>
    </Stack>
  );
}
