import { SerializedPropertyReport } from '@ltvco/refresh-lib/v1';
import { PersonCardLoading } from '../Loading/PersonCardLoading';
import { ResidentNoResults } from '../NoResults/ResidentNoResults';
import { ResidentCards } from '../Cards/ResidentCards';
import { ResidentTable } from '../ResultTables/TableTypes/ResidentTable';

interface ResidentResultsContainerProps {
  results: SerializedPropertyReport | undefined;
  isInTableView?: boolean;
}

export function ResidentResultsContainer({
  results,
  isInTableView,
}: ResidentResultsContainerProps) {
  const count = results?.properties?.[0]?.residents?.length || 0;

  return !results ? (
    <PersonCardLoading />
  ) : count === 0 ? (
    <ResidentNoResults />
  ) : isInTableView ? (
    <ResidentTable results={results} />
  ) : (
    <ResidentCards searchResidentResult={results} />
  );
}
