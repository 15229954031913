import {
  AdvancedSearchURLParams,
  CollectionType,
  OptionType,
} from '@ltvco/refresh-lib/v2';
import {
  NavButton,
  OptionResults,
  SearchHeader,
} from 'routes/SearchPerson/components';
import { Stack, Text, useMediaQuery, useTheme } from '@ltvco/refresh-lib/theme';
import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { capitalizeEachWord } from '@ltvco/refresh-lib/utils';
import { GridViewSharp, TableRowsSharp } from '@mui/icons-material';
import { viewTypes, ViewTypes } from 'routes/SearchPerson/ResultTables/types';
import { ViewTypeContainer } from './components';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { FilterList } from '@mui/icons-material';
import { ActiveFiltersButton } from './components';

export interface SearchHeaderProps {
  options: OptionType[];
  selectedOption: CollectionType;
  setSelectedOption: (value: CollectionType) => void;
  searchData: AdvancedSearchURLParams;
  isFilterCollapsed: boolean;
  setIsFilterCollapsed: (value: boolean) => void;
  currentView?: ViewTypes;
  setCurrentView?: (value: ViewTypes) => void;
}

export const SearchHeaderDesktop = ({
  options,
  selectedOption,
  setSelectedOption,
  searchData,
  currentView,
  setCurrentView,
  isFilterCollapsed,
  setIsFilterCollapsed,
}: SearchHeaderProps) => {
  const capitalizeFullName = capitalizeEachWord(searchData.fullName);

  const { trackEventGA4 } = useContext(AppConfig);
  const isOnPersonResultsTableViewVariation = useFeatureIsOn('prop-2886');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const meetTableViewCriteria =
    isOnPersonResultsTableViewVariation && isDesktop;
  const getActiveFiltersCount = () => {
    let {
      age,
      state,
      city,
      address,
      title,
      company,
      industry,
      level,
      birthYear,
      birthMonth,
      deathYear,
      deathMonth,
      fullName,
      company_size_max,
      company_size_min,
    } = searchData;
    age = age !== '0' ? age : '';
    company_size_max = company_size_max !== '9' ? company_size_max : '';
    company_size_min = company_size_min !== '1' ? company_size_min : '';

    const filters = [
      age,
      state,
      city,
      address,
      title,
      company,
      industry,
      ...(level || []),
      birthYear,
      birthMonth,
      deathYear,
      deathMonth,
      fullName,
      company_size_max,
      company_size_min,
    ];
    return filters.filter(
      (val) => val && val.toString().trim() !== '' && val !== '[]'
    ).length;
  };

  const CollapsedFiltersButton = () => {
    if (!isFilterCollapsed || !isDesktop) {
      return null;
    }

    return (
      <ActiveFiltersButton
        startIcon={<FilterList sx={{ fontSize: 20 }} />}
        onClick={() => setIsFilterCollapsed(false)}
        sx={{ mr: 1 }}
        variant="outlined"
      >
        Filter results ({getActiveFiltersCount()})
      </ActiveFiltersButton>
    );
  };

  const viewOptions = [
    { type: viewTypes.LIST, icon: <GridViewSharp fontSize="small" /> },
    { type: viewTypes.TABLE, icon: <TableRowsSharp fontSize="small" /> },
  ];
  const tableViewResultsForStyles = (meetTableViewCriteria: boolean) => ({
    order: meetTableViewCriteria ? 1 : 2,
    mb: meetTableViewCriteria ? 2 : 0,
    mt: meetTableViewCriteria ? 0 : 2,
  });

  return (
    <SearchHeader
      flexDirection="column"
      alignItems="left"
      pt={2.5}
      pb={2.5}
      display={'flex'}
    >
      <Stack flexDirection="row" order={meetTableViewCriteria ? 2 : 1}>
        <Stack flexDirection="row" flexWrap="wrap" ml={1.25} gap={1}>
          <CollapsedFiltersButton />
          {options
            .filter((option) => option.visible)
            .map((option) => (
              <NavButton
                key={option.value}
                onClick={() => {
                  setSelectedOption(option.value);
                  trackEventGA4({
                    event_name: 'search_results_tab_click',
                    type: option.label,
                  });
                }}
                className={selectedOption === option.value ? 'active' : ''}
              >
                <Stack flexDirection="row" mt={0} alignItems="center">
                  <OptionResults
                    className="options-results"
                    sx={{
                      width: parseInt(option.length) > 9 ? '40px' : '25px',
                      marginRight: '5px',
                    }}
                  >
                    {option.length ? option.length : '0'}
                  </OptionResults>
                  <Text variant="body1" m={0}>
                    {option.label}
                  </Text>
                </Stack>
              </NavButton>
            ))}
        </Stack>

        {meetTableViewCriteria && (
          <Stack flexDirection="row" ml="auto">
            {viewOptions.map((option) => (
              <ViewTypeContainer
                key={option.type}
                className={currentView === option.type ? 'active' : ''}
                onClick={() => setCurrentView && setCurrentView(option.type)}
              >
                {option.icon}
              </ViewTypeContainer>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack
        className="results-for"
        flexDirection="row"
        ml={1.25}
        mt={2}
        sx={tableViewResultsForStyles(meetTableViewCriteria)}
      >
        <Text
          variant="h3"
          sx={{
            fontWeight: 'normal',
            marginRight: '5px',
            marginBottom: '0',
          }}
        >
          Results for:
        </Text>
        <Text variant="h3" m={0}>
          {capitalizeFullName}
        </Text>
      </Stack>
    </SearchHeader>
  );
};
