import { type Record } from '@ltvco/refresh-lib/v1';
import { useAppConstantsContext } from '@ltvco/refresh-lib/ctx';
import { ListColumn, setValidListInfo } from '../TableComponents/ListColumn';
import { AgeColumn } from '../TableComponents/AgeColumn';
import { NameColumn } from '../TableComponents/NameColumn';
import { RawFamilySearchPersonResults } from '@ltvco/refresh-lib/fmt';
import { AdvancedSearchURLParams } from '@ltvco/refresh-lib/v2';
import { familySearchHeaders } from '../constants';
import { useEffect, useState } from 'react';
import { formattedFamilySearchDataForTable } from '../utils';
import {
  FamilySearchRowValue,
  tableTypes,
} from 'routes/SearchPerson/ResultTables/types';
import { TableContainer } from '../TableComponents/TableContainer';

interface FamilySearchTableProps {
  familySearchInfo: AdvancedSearchURLParams;
  familySearchData: RawFamilySearchPersonResults;
}

export interface UsableFamilySearchData {
  name: string;
  nameMatched: boolean;
  familySearchId: string;
  age: string;
  birthInfo: string;
  locations: string[];
  relatives: string[];
}

export const FamilySearchTable = ({
  familySearchData,
  familySearchInfo,
}: FamilySearchTableProps) => {
  const [results, setResults] = useState<Record[]>([]);
  const {
    links: { baseUrl },
  } = useAppConstantsContext();

  const goToFamilySearchReport = (familySearchId: string): void => {
    const windowObjectReference = window.open();
    const windowTarget = windowObjectReference || window;
    windowTarget.location.href = `${baseUrl}/report/ancestor?family_search_id=${familySearchId}`;
  };

  const data: UsableFamilySearchData[] = results.map((person) => {
    const familySearchData = {
      name: person?.name ?? '',
      nameMatched: Boolean(person?.nameMatched),
      familySearchId: person?.familySearchId ?? '',
      bvid: person?.bvid ?? '',
      age: person?.age ?? '',
      birthInfo: person?.birthInfo ?? '',
      locations: (person?.locations ?? []).filter(
        (location): location is string => location !== undefined
      ),
      relatives: person?.relatives ?? [],
    };

    return familySearchData;
  });

  const showRow = (person: UsableFamilySearchData): FamilySearchRowValue => {
    const columnFornName = (
      <NameColumn
        name={person.name}
        nameMatched={person.nameMatched}
        id={person.familySearchId}
        goToReport={goToFamilySearchReport}
      />
    );
    const columnForAge = (
      <AgeColumn age={person.age} birthInfo={person.birthInfo} />
    );
    const columnForLocations = (
      <ListColumn list={setValidListInfo(person.locations)} />
    );
    const columnForRelatives = (
      <ListColumn
        list={setValidListInfo(person?.relatives)}
        goToReport={goToFamilySearchReport}
      />
    );

    const rowValue = {
      id: person.familySearchId,
      name: columnFornName,
      age: columnForAge,
      locations: columnForLocations,
      relatives: columnForRelatives,
    };
    return rowValue;
  };

  useEffect(() => {
    if (familySearchData) {
      const formattedFamily = formattedFamilySearchDataForTable(
        familySearchData,
        familySearchInfo.fName,
        familySearchInfo.lName
      );
      setResults(formattedFamily);
    }
  }, [familySearchData, familySearchInfo]);
  return (
    <TableContainer
      results={data}
      showRow={showRow}
      headers={familySearchHeaders}
      tableType={tableTypes.GENEALOGY}
      goToReport={goToFamilySearchReport}
    />
  );
};
