import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { CircularProgress, Grid, styled } from '@ltvco/refresh-lib/theme';
import { useCustomPlanUpgrade } from '@ltvco/refresh-lib/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import {
  CurrentPlan,
  getBillingFrequency,
  getCustomPlan,
  getPlanTitleAndDescription,
  getPlans,
  planCostPerReport,
} from './plans';
import { useQueryParams } from 'utils/useQueryParams';
import UpgradeLeadForm from 'components/UpgradeLeadForm/UpgradeLeadForm';
import { enqueueSnackbar, useAccount } from '@ltvco/refresh-lib/v1';
import {
  filterActivePaymentMethod,
  ProcessingSpinnerModal,
  TosModal,
  useGetPaymentMethod,
  useGetPlans,
  useProcessBraintree,
  useProcessCard,
  useProcessPayPal,
  useSubmitLegal,
} from '@ltvco/refresh-lib/payments';
import {
  UpgradeFailureModal,
  UpgradePlan,
  UpgradeSuccessModal,
  type Plan,
} from '@ltvco/refresh-lib/payments';
import {
  useGetPaymentType as getPaymentType,
  PaymentsSelectionModal,
  RadioPaymentsModal,
} from '@ltvco/refresh-lib/payments';

const UpgradePlanContent = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3.5, 2),
  marginTop: 'max(0px,calc(40px - 1vw))',
  paddingLeft: 0,
  paddingRight: 0,
}));

enum State {
  None,
  SelectPayment,
  AddPayment,
  ProcessingPayment,
  Success,
  Error,
}

interface UpgradePlanPageProps {
  contactUsHandler: () => void;
}

export const UpgradePlanPage = ({ contactUsHandler }: UpgradePlanPageProps) => {
  const isInDownsellVariation = useFeatureIsOn('prop-2581');
  const isScrollPlansVariation = useFeatureIsOn('RTRN-55');
  const isUpgradeFormVariation = useFeatureIsOn('RTRN-251');
  const { refetch: refetchAccount } = useAccount(true);
  const {
    accountLoaded,
    isStaff,
    isLimitedUser,
    isCancelledSubscription,
    planAmount: amount,
    reportLimit: limit,
    renewalPeriod,
  } = useLimitedPlanInfo();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const [upgradeState, setUpgradeState] = useState(State.None);
  const isState = (state: State) => upgradeState === state;

  const customPlanId = useQueryParams().get('plan_id');
  const brandPlans = useGetPlans();
  const customPlanUpgrade = customPlanId
    ? useCustomPlanUpgrade(customPlanId)
    : null;
  const title = isStaff
    ? 'Staff'
    : getPlanTitleAndDescription(limit, isScrollPlansVariation).title;
  const currentPlan: CurrentPlan = useMemo(() => {
    return {
      title,
      amount,
      renewalPeriod,
      limit,
      costPerReport: planCostPerReport({ amount, renewalPeriod, limit }),
    };
  }, [amount, limit, renewalPeriod, title]);
  const customUpgradeOption =
    customPlanUpgrade?.data?.subscription?.custom_upgrade_option;
  const upgradePlans = brandPlans?.data?.subscription?.upgrade_options;

  useEffect(() => {
    localStorage.setItem(
      'isUpgradeFormVariation',
      JSON.stringify(isUpgradeFormVariation)
    );
  }, [isUpgradeFormVariation]);

  useEffect(() => {
    if (!accountLoaded) return;

    let selectedPlans;
    if (customUpgradeOption) {
      selectedPlans = getCustomPlan(customUpgradeOption, currentPlan);
    } else if (upgradePlans) {
      selectedPlans = getPlans(
        upgradePlans,
        currentPlan,
        isInDownsellVariation,
        isScrollPlansVariation
      );
    }
    if (selectedPlans) {
      setPlans(selectedPlans);
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [accountLoaded, currentPlan, upgradePlans, customUpgradeOption]);

  const showDefaultError = () => {
    enqueueSnackbar(
      'Something went wrong, please try again or contact customer service',
      { variant: 'error' }
    );
  };

  const onSelectPlan = (plan: Plan) => {
    setSelectedPlan(plan);
    setBillingFrecuency(getBillingFrequency(plan.renewalPeriod));
    setBilledPrice(`$${plan.amount}`);
    setSelectedPlanReportLimit(plan.limit);
    setSelectedPlanCostPerReport(plan.costPerReport ?? 0);
    setSelectedPlanSavings(plan.savings ?? 0);
    setOpenTosModal(true);
  };

  //Upgrade Lead Form Test

  const [isUpgradeFormModalOpen, setIsUpgradeFormModalOpen] = useState(
    isUpgradeFormVariation
  );

  const onCloseUpgradeModal = () => {
    setIsUpgradeFormModalOpen(false);
    navigate('/dashboard/billing');
  };

  // TOS

  const updateLegalQuery = useSubmitLegal();
  const [openTosModal, setOpenTosModal] = useState(false);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [billingFrecuency, setBillingFrecuency] = useState('monthly');
  const [billedPrice, setBilledPrice] = useState('');
  const [selectedPlanReportLimit, setSelectedPlanReportLimit] = useState(0);
  const [selectedPlanCostPerReport, setSelectedPlanCostPerReport] = useState(0);
  const [selectedPlanSavings, setSelectedPlanSavings] = useState(0);

  const onCloseTos = () => setOpenTosModal(false);
  const onAcceptTos = () => {
    setProcessingPayment(true);
    updateLegalQuery.mutate(undefined, {
      onSuccess: () => upgradePlan(),
      onError: () => showDefaultError(),
      onSettled: () => onCloseTos(),
    });
  };

  // Payment

  const paymentMethodQuery = useGetPaymentMethod();
  const processCardQuery = useProcessCard();
  const processPayPalQuery = useProcessPayPal();
  const processBraintreeQuery = useProcessBraintree();
  const [upgradeErrors, setUpgradeErrors] = useState<string[]>([]);
  const [hadPaymentErrors, setHadPaymentErrors] = useState(false);

  const upgradePlan = async () => {
    const activePaymentQueryRefetched = await paymentMethodQuery.refetch();
    const activePayment = filterActivePaymentMethod(
      activePaymentQueryRefetched?.data
    );

    if (!selectedPlan || !activePayment) return;

    setUpgradeState(State.ProcessingPayment);

    const plan = { unique_key: selectedPlan.key };
    const queryOptions: any = {
      onSuccess: () => {
        refetchAccount();
        setUpgradeState(State.Success);
      },
      onError(error: Error) {
        let cause = error.cause as string[];
        const stringifiedErrors = cause?.join(', ');

        if (stringifiedErrors === 'Unknown Checkout Failure Reason') {
          cause = [
            'Your payment failed, please try again or choose a different payment method.',
          ];
        }

        setUpgradeErrors(cause);
        setUpgradeState(State.Error);
      },
    };

    interface MutationFunctions {
      paypal: typeof processPayPalQuery;
      braintree: typeof processBraintreeQuery;
      card: typeof processCardQuery;
    }

    const mutationFunctions: MutationFunctions = {
      paypal: processPayPalQuery,
      braintree: processBraintreeQuery,
      card: processCardQuery,
    };

    const paymentType: 'card' | 'braintree' | 'paypal' =
      getPaymentType(activePayment);

    const mutationFunction = mutationFunctions[paymentType] || processCardQuery;

    const mutationParams = {
      plan,
      ...((paymentType === 'paypal' && {
        paypalId: activePayment.id,
      }) as { paypalId: number }),
      ...((paymentType === 'card' && {
        cardId: activePayment.id,
      }) as {
        cardId: number;
      }),
      ...((paymentType === 'braintree' && {
        braintreeId: activePayment.id,
      }) as { braintreeId: number }),
      source_of_payment: 'manual_upgrade_attempt',
    };
    setProcessingPayment(false);
    mutationFunction.mutate(mutationParams, queryOptions);
  };

  const hasPlans = plans.length > 0;
  const ctaText = !isUpgradeFormVariation
    ? 'Accept and Upgrade'
    : `Get this plan for ${billedPrice}`;

  const handleAddPaymentMethod = () => {
    setUpgradeState(State.AddPayment);
  };

  const handleSelectedPaymentMethod = () => {
    if (hadPaymentErrors) {
      setHadPaymentErrors(false);
      upgradePlan();
    } else {
      setUpgradeState(State.None);
    }
  };

  const handleClosePaymentSelectionModal = () => {
    if (hadPaymentErrors) {
      setHadPaymentErrors(false);
      setUpgradeState(State.Error);
    } else {
      setUpgradeState(State.None);
    }
  };

  const handleCloseAddPaymentModal = () => {
    setUpgradeState(State.None);
  };

  return (
    <UpgradePlanContent container={hasPlans}>
      {isLoading && <CircularProgress sx={{ my: 30 }} />}

      {!isLoading && (
        <>
          {!isUpgradeFormVariation ? (
            <UpgradePlan
              currentPlan={{
                title: currentPlan.title,
                limit: isLimitedUser ? currentPlan.limit : 'unlimited',
                costPerReport: currentPlan.costPerReport,
                canceled: isCancelledSubscription,
              }}
              plans={plans}
              onSelectPlan={onSelectPlan}
              onContactUs={contactUsHandler}
              isScrollPlansTest={isScrollPlansVariation}
            />
          ) : (
            <UpgradeLeadForm
              currentPlan={{
                title: currentPlan.title,
                limit: isLimitedUser ? currentPlan.limit : 'unlimited',
                costPerReport: currentPlan.costPerReport,
                canceled: isCancelledSubscription,
              }}
              plans={plans}
              isOpen={isUpgradeFormModalOpen}
              onClose={onCloseUpgradeModal}
              onSelectPlan={onSelectPlan}
            />
          )}
          <TosModal
            open={openTosModal}
            showCheckbox={true}
            billingFrecuency={billingFrecuency}
            billedPrice={billedPrice}
            cta={{
              text: ctaText,
              extraWide: true,
              processingPayment,
            }}
            showPaymentMethod={true}
            acceptTos={onAcceptTos}
            onChangePaymentMethod={() => setUpgradeState(State.SelectPayment)}
            onClose={onCloseTos}
            onUpgradeFormVariation={isUpgradeFormVariation}
            reportLimit={selectedPlanReportLimit}
            costPerReport={selectedPlanCostPerReport}
            savings={selectedPlanSavings}
          />

          <PaymentsSelectionModal
            open={isState(State.SelectPayment)}
            cta="Use This Payment Method"
            onAddPaymentMethod={handleAddPaymentMethod}
            onSelectedPaymentMethod={handleSelectedPaymentMethod}
            onClose={handleClosePaymentSelectionModal}
          />

          <RadioPaymentsModal
            isOpen={isState(State.AddPayment)}
            onCloseHandle={handleCloseAddPaymentModal}
          />

          <ProcessingSpinnerModal open={isState(State.ProcessingPayment)} />

          <UpgradeSuccessModal
            open={isState(State.Success)}
            onAccept={() => {
              setUpgradeState(State.None);

              navigate('/dashboard');
            }}
          />

          <UpgradeFailureModal
            open={isState(State.Error)}
            errors={upgradeErrors}
            onTryAgain={() => upgradePlan()}
            onChangeCard={() => {
              setHadPaymentErrors(true);
              setUpgradeState(State.SelectPayment);
            }}
            onClose={() => {
              setUpgradeState(State.None);
              if (isUpgradeFormVariation) {
                navigate('/dashboard/billing');
              }
            }}
          />
        </>
      )}
    </UpgradePlanContent>
  );
};
