import { Dispatch, SetStateAction, useContext } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { constants } from 'appConstants';
import { publish } from '@ltvco/refresh-lib';
import { useSession } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import {
  LanguageTutor,
  FamilyThree,
  Monitoring,
  FraudScan,
  IdentityBg,
  TheftInsurance,
} from 'icons';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

export const AccountInformationOptions = [
  {
    name: 'Edit Name',
    display: 'Edit Name',
    value: 'Edit Name',
    constantValue: 'EDIT_NAME',
  },
  {
    name: 'Edit Email',
    display: 'Edit Email',
    value: 'Edit Email',
    constantValue: 'EDIT_EMAIL',
  },
  {
    name: 'Add Phone Number',
    display: 'Edit Phone Number',
    value: 'Add Phone Number',
    constantValue: 'ADD_PHONE_NUMBER',
  },
  {
    name: 'Change Password',
    display: 'Change Password',
    value: 'Change Password',
    constantValue: 'CHANGE_PASSWORD',
  },
  {
    name: 'Edit Payment Info',
    display: 'Edit Payment Info',
    value: 'Edit Payment Info',
    constantValue: 'EDIT_PAYMENT_INFO',
  },
];

export const editOptionClicked = (
  editing: string | null,
  openModal: (open: boolean) => void,
  setEditing: Dispatch<SetStateAction<string | null>>,
  customOptions?: {
    name: string;
    display: string;
    value: string;
    constantValue: string;
  }[]
) => {
  const options = customOptions || AccountInformationOptions;
  if (editing === 'Edit Payment Info') {
    openModal(true);
  } else {
    setEditing(
      options.find((option) => {
        return option.name === editing;
      })?.constantValue || editing
    );
  }
};

export const GenericCtaCardProps = (
  navigate: NavigateFunction,
  isInVariation: boolean,
  onVariationCtaClick: (text: string) => void
) => {
  // has_sso_connections
  const {
    session: { account },
  } = useSession();
  const { trackEventGA4 } = useContext(AppConfig);

  // AB Tests
  const isInDashboardFeaturesVariation = useFeatureIsOn('OAR-1410');
  const { isMobile } = useScreenSize();

  const handleCtaClick = (
    ctaLabel: string,
    actionParam: string,
    eventSite: string,
    isPublish: boolean
  ) => {
    trackEventGA4({
      event_name: 'navigation_click',
      type: eventSite,
      interacted_from: 'my_account',
    });

    isInVariation
      ? onVariationCtaClick(ctaLabel)
      : isPublish
      ? publish(actionParam)
      : navigate(actionParam);
  };

  // Google-enabled brands: BeenVerified, Bumper, MoneyBot5000, NeighborWho, Number Guru, Ownerly, PeopleLooker, PeopleSmart, ReversePhone, Wingmate LC 04/07/25
  // Apple-enabled brands: BeenVerified, Bumper, NeighborWho, Number Guru, PeopleLooker, Wingmate LC 04/07/25
  const sso_enabled = {
    google: true,
    apple: true,
  };

  const props = [
    {
      headerText: 'Connect or Edit Login Options',
      bodyText: `Add your ${
        sso_enabled.google && sso_enabled.apple
          ? 'Google or Apple'
          : sso_enabled.google
          ? 'Google'
          : sso_enabled.apple
          ? 'Apple'
          : ''
      } Login on top of your account password & email`,
      buttonText: 'Edit Login Options',
      imgUrl: constants.images.imgSingleSignOnCta,
      onCtaClick: () =>
        window.open('https://www.beenverified.com/sso_connections', '_blank'),
      dataCy: 'login_options_cta',
    },
    {
      headerText: 'Search property reports',
      bodyText: 'Search an address to find out home value, ownership and more',
      buttonText: 'Search an address now',
      imgUrl: constants.images.imgAddressGeneric,
      onCtaClick: () =>
        handleCtaClick(
          'property report',
          'openPropertySearch',
          'property',
          true
        ),
    },
    {
      headerText: 'Search background reports',
      bodyText: 'Find arrest records, contact information and more',
      buttonText: 'Search by name now',
      imgUrl: constants.images.imgSpecializedBackgroundCheck,

      onCtaClick: () =>
        handleCtaClick(
          ' background report',
          'openPersonSearch',
          'people',
          true
        ),
    },
    //TODO: Uncomment when these features are ready
    // {
    //   headerText: 'Monitor 10 different reports',
    //   bodyText: 'Get alerted about new data on reports as we become aware',
    //   buttonText: 'See more',
    //   imgUrl: constants.imgBell,

    //   onCtaClick: () => navigate('/dashboard/alertme'),
    // },
    // {
    //   headerText: 'Monitor your online identity',
    //   bodyText:
    //     'Monitor your online identity to get notified if any changes are detected',
    //   buttonText: 'Start monitoring now',
    //   imgUrl: constants.imgIdHub,
    //   onCtaClick: () => navigate('/dashboard/identity-hub'),
    // },
    {
      headerText: 'Dark Web Scan',
      bodyText:
        'See if the email you signed up with may have been leaked in a data breach',
      buttonText: 'Scan now',
      imgUrl: constants.images.imgDarkWebScan,
      onCtaClick: () =>
        handleCtaClick('dark web', '/dashboard/dark-web', 'dark_web', false),
      dataCy: 'dark_web_cta',
    },
    {
      headerText: 'Unclaimed Money',
      bodyText: 'Did you know 1 in 10 American have unclaimed Money?',
      buttonText: 'Find out now',
      imgUrl: constants.images.imgSpecializedUnclaimedMoney,
      onCtaClick: () =>
        handleCtaClick(
          'unclaimed money',
          '/dashboard/unclaimed-money',
          'unclaimed_money',
          false
        ),
      dataCy: 'unclaimed_cta',
    },
  ];

  const dashboardFeaturesprops = [
    {
      headerText: 'Search property reports',
      bodyText: 'Search an address to find out home value, ownership and more',
      buttonText: isMobile ? 'Search now' : 'Search an address now',
      imgUrl: constants.images.imgAddressGeneric,
      onCtaClick: () =>
        handleCtaClick(
          'property report',
          'openPropertySearch',
          'property',
          true
        ),
    },
    {
      headerText: 'Search background reports',
      bodyText: 'Find arrest records, contact information and more',
      buttonText: isMobile ? 'Search now' : 'Search by name now',
      imgUrl: constants.images.imgSpecializedBackgroundCheck,

      onCtaClick: () =>
        handleCtaClick(
          ' background report',
          'openPersonSearch',
          'people',
          true
        ),
    },
    {
      headerText: 'Dark Web Scan',
      bodyText:
        'See if the email you signed up with may have been leaked in a data breach',
      buttonText: 'Scan now',
      imgUrl: constants.images.imgDarkWebScan,
      onCtaClick: () =>
        handleCtaClick('dark web', '/dashboard/dark-web', 'dark_web', false),
      dataCy: 'dark_web_cta',
    },
    {
      headerText: 'Language Tutor',
      bodyText: 'Speak a new language with confidence',
      buttonText: 'Learn more',
      icon: LanguageTutor,
      onCtaClick: () =>
        handleCtaClick(
          'language tutor',
          '/dashboard/Language',
          'language',
          false
        ),
    },
    {
      headerText: 'Family Tree',
      bodyText:
        'Start building your family tree! Organize your information now',
      buttonText: 'Learn more',
      icon: FamilyThree,
      onCtaClick: () =>
        handleCtaClick('family-tree', '/family-tree', 'family', false),
    },
    {
      headerText: 'Identity & Credit Alerts',
      bodyText: 'Monitor potential identity and credit record activity',
      buttonText: 'Learn more',
      icon: IdentityBg,
      onCtaClick: () =>
        handleCtaClick(
          'identity & credit alerts',
          '/dashboard/id-monitor?escalated=true',
          'identity',
          false
        ),
    },
    {
      headerText: 'Monitor Your Reports',
      bodyText: 'Receive updates if we detect changes',
      buttonText: 'Search now',
      icon: Monitoring,
      onCtaClick: () =>
        handleCtaClick(
          'monitor reports',
          '/dashboard/monitoring',
          'reports',
          false
        ),
    },
    {
      headerText: 'Fraud Scan',
      bodyText:
        'Check phone numbers and emails for threats and fraudulent info',
      buttonText: 'Scan now',
      icon: FraudScan,
      onCtaClick: () =>
        handleCtaClick('fraud scan', '/dashboard/fraud', 'fraud', false),
    },
    {
      headerText: '$1M ID Theft Insurance',
      bodyText:
        'Protect your identity with identity theft insurance and restoration services',
      buttonText: 'Learn more',
      icon: TheftInsurance,
      onCtaClick: () =>
        handleCtaClick(
          'identity theft insurance',
          '/dashboard/id-monitor?escalated=true',
          'insurance',
          false
        ),
    },
  ];

  const selectedProps = isInDashboardFeaturesVariation
    ? dashboardFeaturesprops
    : props;

  return {
    ctaCardProps: selectedProps,
    length: selectedProps.length,
  };
};
