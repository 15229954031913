import {
  openReportInExistingTab,
  openReportInNewTab,
  type Record,
} from '@ltvco/refresh-lib/v1';
import { useAppConstantsContext } from '@ltvco/refresh-lib/ctx';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import {
  ListColumn,
  ListInfo,
  setValidListInfo,
} from '../TableComponents/ListColumn';
import { AgeColumn } from '../TableComponents/AgeColumn';
import { NameColumn } from '../TableComponents/NameColumn';
import { personHeaders } from '../constants';
import { TableContainer } from '../TableComponents/TableContainer';
import {
  PersonRowValue,
  RelativeNotSerialized,
  SearchType,
  tableTypes,
} from '../types';

interface PersonTableProps {
  results: Record[];
}

export interface UsablePersonData {
  name: string;
  nameMatched: boolean;
  bvid: string;
  age: string;
  birthInfo: string;
  aliases: string[];
  locations: string[];
  relatives: string[];
  relativesNotSerialized:
    | RelativeNotSerialized
    | RelativeNotSerialized[]
    | ListInfo[];
  validRelativesIds: string[];
}

export const PersonTable = ({ results }: PersonTableProps) => {
  const {
    links: { baseUrl },
    featureFlags: { shouldOpenReportInCurrentTab },
  } = useAppConstantsContext();
  const isInPartialAddressesVariation = useFeatureIsOn('oar-1075');

  const goToReport = (bvid: string) => {
    const tabData = {
      bvid: bvid || '',
      searchType: 'person' as SearchType,
      showPartialAddresses: isInPartialAddressesVariation,
    };
    if (shouldOpenReportInCurrentTab) {
      openReportInExistingTab(tabData, baseUrl);
    } else {
      openReportInNewTab(tabData, baseUrl);
    }
  };

  const data: UsablePersonData[] = results.map((person) => {
    const personData = {
      name: person?.name ?? '',
      nameMatched: Boolean(person?.nameMatched),
      bvid: person?.bvid ?? '',
      age: person?.age ?? '',
      birthInfo: person?.birthInfo ?? '',
      aliases: person?.aliases ?? [],
      locations: (person?.locations ?? []).filter(
        (location): location is string => location !== undefined
      ),
      relatives: person?.relatives ?? [],
      relativesNotSerialized: person?.relativesNotSerialized ?? [],
      validRelativesIds: Array.isArray(person.relativesNotSerialized)
        ? person.relativesNotSerialized
            .map((relative) => relative.bvid)
            .filter((bvid): bvid is string => bvid !== undefined)
        : [],
    };

    return personData;
  });

  const showRow = (person: UsablePersonData): PersonRowValue => {
    const columnFornName = (
      <NameColumn
        name={person.name}
        nameMatched={person.nameMatched}
        id={person.bvid}
        goToReport={goToReport}
      />
    );
    const columnForAge = (
      <AgeColumn age={person.age} birthInfo={person.birthInfo} />
    );
    const columnForAliases = (
      <ListColumn list={setValidListInfo(person.aliases)} />
    );
    const columnForLocations = (
      <ListColumn list={setValidListInfo(person.locations)} />
    );
    const columnForRelatives = (
      <ListColumn
        list={setValidListInfo(person.relatives, person.validRelativesIds)}
        goToReport={goToReport}
      />
    );

    const rowValue = {
      id: person.bvid,
      name: columnFornName,
      age: columnForAge,
      aliases: columnForAliases,
      locations: columnForLocations,
      relatives: columnForRelatives,
    };
    return rowValue;
  };
  return (
    <TableContainer
      tableType={tableTypes.PERSON}
      results={data}
      showRow={showRow}
      headers={personHeaders}
      goToReport={goToReport}
    />
  );
};
