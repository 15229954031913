import { Box, Button, styled } from '@ltvco/refresh-lib/theme';

export const SearchHeaderContainerCarousel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  zIndex: 1,
  backgroundColor: theme.palette.primary.contrastText,
  paddingTop: '15px',
  paddingBottom: '15px',
  top: '150px',
  behavior: 'smooth',
  [theme.breakpoints.up('sm')]: {
    top: '148px',
    padding: '15px',
  },
}));

export const ViewTypeContainer = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  width: '42px',
  height: '42px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  '&.active': {
    backgroundColor: theme.palette.success.light,
  },
  '&.disabled': {
    backgroundColor: 'transparent',
    color: theme.palette.text.disabled,
  },
}));

export const ActiveFiltersButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '10px',
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  paddingX: 3,
  paddingY: 1,
  minWidth: '210px',
  height: '42px',
}));
